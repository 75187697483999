
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, EventBus } from "../../../config";
import { authHeader } from "../../../services/auth";

@Component
export default class Login extends Vue {
  /* eslint-disable */

  public allLenders: any = null;
  public vendors: any = [];
  public type1Vendor: any = [];
  public type2Vendor: any = null;
  public agentList: any = [];
  public removedVendorsAndLenders: any = [];
  public brokerName: any = null;
  public isOnBoardingCompleted: any = false;
  public error: any = {
    show: false,
    msg: "",
  };
  public isComplete = false;

  public async getListOfAddedVendors() {
    this.callLoader(true, 2);
    try {
      const response = await Axios.post(
        BASE_API_URL + "broker/getListOfAddedVendors",
        {
          id: this.$route.query.id,
        },
        {
          headers: authHeader(),
        }
      );

      if (response.status === 201) {
        this.type1Vendor = response.data.type1Vendor;
        this.allLenders = response.data.lenders;
        this.type2Vendor = response.data.type2Vendor;
        this.brokerName = response.data.brokerName;
        this.isOnBoardingCompleted = response.data.isOnBoardingCompleted;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 2);
  }

  public async getListOfVendors() {
    this.callLoader(true, 2);
    try {
      const response = await Axios.get(
        BASE_API_URL + "broker/getListOfVendors",
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.vendors = response.data.vendors;
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, 2);
  }

  public addVendors() {
    this.type1Vendor.push({
      vendorTypeId: 1,
      isDefault: false,
      companyName: null,
      companyUrl: null,
      credentials: {
        userName: null,
        password: null,
        brokerUserId: this.$route.query.id,
        // userType: 2,
      },
    });
  }

  public removeVendors(index) {
    let spliceData = this.type1Vendor.splice(index, 1);
    if (spliceData.length > 0 && spliceData[0]._id) {
      this.removedVendorsAndLenders.push(spliceData[0]);
    }
  }

  public addLenders() {
    this.allLenders.push({
      type: 10,
      name: null,
      website: null,
      class: "Lender",
      credentials: {
        userName: null,
        brokerUserId: this.$route.query.id,
        password: null,
        // userType: 2,
        processingCompanyUserName: null,
        processingCompanyPassword: null,
      },
    });
    console.log(this.allLenders);
  }

  public removeLenders(index) {
    let spliceData = this.allLenders.splice(index, 1);
    if (spliceData.length > 0 && spliceData[0]._id) {
      this.removedVendorsAndLenders.push(spliceData[0]);
    }
  }

  public async saveVendorsInfo() {
    this.callLoader(true, 1);
    try {
      if (this.isComplete) {
        if (this.type1Vendor.length == 0) {
          this.error.show = true;
          this.error.msg = "Please Provide At Least One Credit Information";
          this.callLoader(false, null);
          return;
        }
        let notFilledInType2 = this.type2Vendor.find(
          (e) =>
            (e.vendorTypeId === 3 &&
              ((!e.credentials.userName && !e.credentials.password) ||
                (e.credentials.userName && !e.credentials.password) ||
                (e.credentials.password && !e.credentials.userName))) ||
            (e.credentials.userName && !e.credentials.password) ||
            (e.credentials.password && !e.credentials.userName)
        );
        if (notFilledInType2) {
          this.error.show = true;
          this.error.msg = `Please provide all info about ${notFilledInType2.companyName}`;
          this.callLoader(false, null);
          return;
        }
        if (this.allLenders.length > 0) {
          let notFilledLenders = this.allLenders.find(
            (e) =>
              (e.credentials.processingCompanyPassword &&
                !e.credentials.processingCompanyUserName) ||
              (!e.credentials.processingCompanyPassword &&
                e.credentials.processingCompanyUserName)
          );
          if (notFilledLenders) {
            console.log(notFilledLenders, "notFilledLenders");
            let name =
              notFilledLenders._id != "New"
                ? await this.getNameOfLender(notFilledLenders._id)
                : notFilledLenders.name;
            this.error.show = true;
            this.error.msg = `Please provide all info about ${name}`;
            this.callLoader(false, null);
            return;
          }
        }
      }
      const response = await Axios.post(
        BASE_API_URL + "broker/saveOnBoardingVendors",
        {
          type1Vendor: this.type1Vendor,
          type2Vendor: this.type2Vendor,
          removedVendorsAndLenders: this.removedVendorsAndLenders,
          isComplete: this.isComplete,
          lenders: this.allLenders,
        },
        { headers: authHeader() }
      );
      if (response.status == 201) {
        this.$snotify.success("Data Saved Successfully");
        await this.getListOfAddedVendors();
        await this.getListOfVendors();
        await this.getAgentList();
        if (this.isComplete) {
          this.$router.push("/broker-dashboard");
          EventBus.$emit("refreshHeaderAfterOnBoarding");
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

  public getNameOfLender(id) {
    let data = this.agentList.find((e) => e._id.toString() === id.toString());
    return data.name;
  }

  public async getAgentList() {
    let res = await Axios.get(BASE_API_URL + "agent/getActiveAgentList/" + 10, {
      headers: authHeader(),
    });
    this.agentList = res.data ? res.data : [];
  }

  public OrganizeValue(index, event) {
    if (event.target.value != "New")
      this.allLenders[index].website = this.agentList.find(
        (e) => e._id == event.target.value
      ).website;
  }

  public OrganizeValueOfVendor(index, event, vendor) {
    if (event.target.value != "New") {
      let vendorData = this.vendors.find((e) => e._id == event.target.value);
      this.type1Vendor[index].companyUrl = vendorData.companyUrl;
      this.type1Vendor[index].isDefault = vendorData.isDefault;
    } else if (vendor._id == "New") {
      vendor.companyName = null;
      vendor.companyUrl = null;
      vendor.credentials.userName = null;
      vendor.credentials.password = null;
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }
  async mounted() {
    await this.getListOfVendors();
    await this.getListOfAddedVendors();
    await this.getAgentList();
  }
}
